const LocationReviewsData = [
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Brian Duffy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Thomas Smith DDS",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melissa M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Kelsey Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stacey G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Robert Ruiz",
        "service_type": "Garage Door Repair",
        "tech_name": "Billy T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Luke Campbell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelly K.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Nicole Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Jennifer Martin MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Amy Hester",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jared A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hill",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Kayla Ramirez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremiah M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Eddie Thompson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Deborah P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Curtis Robles",
        "service_type": "Garage Door Opener",
        "tech_name": "Bob M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Gabrielle Fisher",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cheryl M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Megan Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Crystal W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Curtis Santos",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "James Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sandra P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Samuel Perry",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Brittany Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Walter D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Amy Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Aaron H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Brittany Lopez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Elizabeth D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Laura Ramsey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Brian Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tanya S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Mario Moses",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Katie G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Preston Jordan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "David Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Christine Guzman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Christopher Humphrey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Catherine T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Christopher Parker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mary C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Rhonda Harris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Natalie B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Brandon Nichols",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Lisa Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patrick A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Julian Silva",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Rebecca Kelley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kerry P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Mark Osborne",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christina S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Heather Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sandra C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Tracy Hoffman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeanette S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Scott Young",
        "service_type": "Garage Door Installation",
        "tech_name": "Jonathan N.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "James Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Maria P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Daniel Larson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Harry G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Emily Odom",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Travis C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Joshua Shannon",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Bonnie Martinez",
        "service_type": "Garage Door Repair",
        "tech_name": "Logan W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Tara Clements",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gabrielle B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Amanda Glover",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Jacob Spence",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Abigail P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Tracy Baird",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Jose Norton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jacob L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Pamela Klein",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eric B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Mr. Eric Velasquez MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Mrs. Mary Aguirre MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeff K.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Richard Maynard",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Anthony Weaver",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Peggy F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Frederick Diaz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tammy T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Nathan Scott",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stacy R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Nicholas Benjamin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dale H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Michael Phillips",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Aimee C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Zachary Bowman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Kenneth Carter",
        "service_type": "Garage Door Off Track",
        "tech_name": "Meghan M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Anthony Sherman",
        "service_type": "Garage Door Opener",
        "tech_name": "Anthony G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Jonathan Atkinson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Evan W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Peter Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cathy S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Meagan Pineda",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amy S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Christopher Clay",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Ashley Jensen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Edward S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Debra Daniels",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Kelsey Anthony",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Kristi Cook",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Roger Porter",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Daniel Hendrix",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jacqueline S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Brandon Richardson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Francisco G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Katie Cochran",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charles B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "William Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heather F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Amber Barnes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angela H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Rachel Boyd",
        "service_type": "Garage Door Opener",
        "tech_name": "Kayla J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Steven Rodriguez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shelby H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Jennifer Brock",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steve C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Jason Park",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Clinton Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephen S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Thomas Valentine",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Emma K.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Brandon Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Keith P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Danielle Moore",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alyssa C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Alan Hansen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alison M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "John Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emily W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Brian Lane",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Diana W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Michelle Harper",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Beth L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Jessica Rios",
        "service_type": "Garage Door Opener",
        "tech_name": "Meghan E.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Anthony Singh",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tony S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Wendy Lopez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Crystal C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Roger Stevens",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael A.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Peter Bird",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Haley W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "John Boyle",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Janet C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Eric Garcia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christian M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "James Barajas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Leslie T.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Kristina Roberts",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Xavier Mueller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joshua A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Erica Soto",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Dustin Quinn",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephanie S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Frank Stewart",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Anthony Burton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jorge M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Mary Tran",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert E.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Felicia Mendez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Philip L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Sharon Mays",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Diana Hunter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stacey S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Christy Gomez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anthony P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Leah Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Diana J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Melissa Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "April R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Stacy Cruz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jenna M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Norman Pierce",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Jane Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Matthew N.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Julie Turner",
        "service_type": "Garage Door Installation",
        "tech_name": "Courtney Y.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Felicia Hughes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rebecca V.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Rhonda Jackson",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Austin Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amy M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "John Rodriguez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melanie W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Margaret Gonzales",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Angela S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Lindsey Trevino",
        "service_type": "Garage Door Insulation",
        "tech_name": "John F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Stacy Jarvis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kevin R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Michelle Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dean W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Kenneth Gutierrez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Catherine L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Samantha Bailey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul T.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Vanessa Mckinney",
        "service_type": "Garage Door Installation",
        "tech_name": "Erica J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Debra Meyer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Donna Lopez",
        "service_type": "Garage Door Opener",
        "tech_name": "Kristina D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Steve Leonard",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alan K.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Alyssa Thomas MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michelle V.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Colleen Benjamin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bryan C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Kyle Mullen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Allison Gordon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John N.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Christopher Coleman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy K.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Tiffany Sandoval",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lucas H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Suzanne Gonzalez",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Breanna Price",
        "service_type": "Garage Door Repair",
        "tech_name": "Jacob P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Amy Simmons",
        "service_type": "Garage Door Opener",
        "tech_name": "Desiree S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "William Dixon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Julie G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Christina Wright",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Peter M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Parker Lewis",
        "service_type": "Garage Door Installation",
        "tech_name": "Kathy J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Virginia Rollins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Morgan M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Gavin Brady",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Vernon C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Sarah Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "Johnny W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Matthew Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathan W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Bryan Jackson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Rebecca Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Debbie M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Jessica Bradley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heather W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Leonard Scott",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Adam B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Thomas Woodard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Billy G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "John Tyler",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Austin W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Gregory Barnett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ryan C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Tracy Myers",
        "service_type": "Garage Door Opener",
        "tech_name": "Tyler W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Rocha",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard N.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Shawn Ball",
        "service_type": "Garage Door Opener",
        "tech_name": "Noah C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Travis Lopez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dalton F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Amy Hall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Ian Wallace",
        "service_type": "Garage Door Opener",
        "tech_name": "Jane C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "David Torres",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gina H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Keith Simpson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karen R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Daniel Estrada",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robin Y.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Heather Austin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joshua J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Matthew Martin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Linda M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Jessica Garza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "David Roberts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mercedes R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Riley Wagner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cynthia S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Hannah Gibson",
        "service_type": "Garage Door Repair",
        "tech_name": "John S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Maurice Flores",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Christopher White",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Eric W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Jordan Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Jasmine Short",
        "service_type": "Garage Door Off Track",
        "tech_name": "Scott A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Katie Kelly",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daniel F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Deborah Bryant",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Ashley Morse DVM",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Chloe B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Adrienne Phillips",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Benjamin G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Patricia Ross",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Traci H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Tina Copeland",
        "service_type": "Garage Door Repair",
        "tech_name": "Dennis R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Ralph Jacobs",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Patricia Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Jessica Murphy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Megan S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Wendy Walsh",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "James Mcdonald",
        "service_type": "Garage Door Repair",
        "tech_name": "Cathy M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Manuel Hill",
        "service_type": "Garage Door Services",
        "tech_name": "Lori W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Frank Alexander",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Laura Cook",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Russell B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Amy Andersen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Mandy Kerr",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Cheyenne Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Wesley C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Emma Guzman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Stephen Burns",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amy C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Darryl Dominguez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Christopher Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caroline H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Renee Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Christopher Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Laura M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Oscar Carey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Michael Maxwell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Cheryl Dickerson",
        "service_type": "Garage Door Repair",
        "tech_name": "Scott S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Kimberly Fitzpatrick",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Robin Barr",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Laura Cowan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alisha H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Debra Dennis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melissa S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Charles Griffith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Teresa Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Johnny D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Crystal Moran",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Vanessa R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Amanda Glover",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kim S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Sara Richmond",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Brandy Walker",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Teresa Robertson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joe W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Michael Jacobson",
        "service_type": "Garage Door Repair",
        "tech_name": "Eric L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Johnathan Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brandon B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Logan Johnston",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Eric Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jasmine S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Mr. Austin Hampton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carla A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Angela Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tracy G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Matthew Knight",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jacqueline S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Roy Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeffrey S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Michelle Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Miranda P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Nancy Dennis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Benjamin B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Adrian Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Brittney Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lori B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Lisa Cunningham",
        "service_type": "Garage Door Services",
        "tech_name": "Kaitlyn W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Gabriella Jordan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Teresa H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "George Riggs",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Beverly C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Sean Bennett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Heather W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Stephanie Vega",
        "service_type": "Garage Door Opener",
        "tech_name": "Benjamin J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Mark Escobar",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicole G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Gill",
        "service_type": "Garage Door Installation",
        "tech_name": "Crystal C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Victoria Eaton",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "James Dyer",
        "service_type": "Garage Door Services",
        "tech_name": "Brooke S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Colin Conrad",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Felicia S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Justin Fitzgerald",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charlene N.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Jamie Kim",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Marie C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Jeanette Mack",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stephanie T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Ronald Neal",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Maria Elliott",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Samantha C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Robert Lee",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Philip Morgan",
        "service_type": "Garage Door Opener",
        "tech_name": "Barbara J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Michael Wong",
        "service_type": "Garage Door Services",
        "tech_name": "Rebecca C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Daniel Edwards",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Megan M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Mark Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sandra H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Lisa Gomez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dennis J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Emma Wilson",
        "service_type": "Garage Door Installation",
        "tech_name": "Susan S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Shane Tucker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Courtney Winters",
        "service_type": "Garage Door Repair",
        "tech_name": "Jose F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Tara Harvey",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Donald H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "James Perez",
        "service_type": "Garage Door Installation",
        "tech_name": "Brittany F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Tina Benjamin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rodney C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Lewis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Katrina Lambert",
        "service_type": "Garage Door Installation",
        "tech_name": "Sharon M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Gary Molina",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carla Y.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Julia Sanford",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Marcus L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Kristina Reynolds",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amber H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Steven Shannon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Dan Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stephanie A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Richard Cohen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Angela F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "John Small",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Michelle Hall",
        "service_type": "Garage Door Services",
        "tech_name": "Angela B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Vincent Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Emily Watkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Annette S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Kim Sanchez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samuel C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Renee Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Richard E.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Anthony Wheeler",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Edward G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "David Ross",
        "service_type": "Garage Door Repair",
        "tech_name": "Gregory L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Robert Le",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Logan H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Kelly Page",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Antonio B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Lisa Ritter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Debra C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Tara Walker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Chelsey F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "John Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Matthew Wright",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Katelyn Cochran",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jane B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Catherine Kerr PhD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Scott Bryan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tammy M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Cindy Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Megan M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Robert Willis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Aaron M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Rebecca Walker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michelle B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Juan Sparks",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Debra C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Elijah Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Greg V.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Kayla Moss",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carrie D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Melissa Hensley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Danielle Mullins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Barbara P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Logan Jennings",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffery S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Luke Peterson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marcus M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Maria Lewis",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Emma Allen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Michael Henderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Megan O.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Tami Dunlap",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Brian Freeman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Lindsay Jenkins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jacob J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Matthew Thompson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eugene J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Joe Gibson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Clifford J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Matthew Walker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jodi O.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Raymond Holmes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Angela Carr",
        "service_type": "Garage Door Services",
        "tech_name": "Donald B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Alyssa Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ryan G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Amanda Cobb",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Carl Kramer",
        "service_type": "Garage Door Opener",
        "tech_name": "Jason T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Joy Hill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tonya K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Anthony Cole",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Linda C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Brandon Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kathleen W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Jesse Allen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Adam A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Shelley Bishop",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael V.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Karen Berry",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gary P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Tracy Hodges",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Brenda Ward",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Javier Wu",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tracy E.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Jennifer Fields",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Patricia Chavez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Laura G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Dakota Richards",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Derek S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Jason Guzman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacob L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "William Mora",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dana W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Aaron Chavez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandy R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Kylie Moore",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Samantha P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "David White",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gary B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Kathy Spence",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patricia J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Monica Hicks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Natasha P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "George Bartlett",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bethany W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Joseph Holt",
        "service_type": "Garage Door Opener",
        "tech_name": "Kelly J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Anthony Wiggins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eddie M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Kenneth Reed",
        "service_type": "Garage Door Insulation",
        "tech_name": "Caleb H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Ryan Maxwell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paula M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Jose Payne",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tiffany W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Kevin Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paula R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Melissa Zimmerman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heather W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Courtney Simpson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jamie F.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Paul Kim",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ian A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Jessica Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gerald K.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Andrea Phillips",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacob C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Melissa Russo",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Gregory Parker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Michelle Cooper",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Victoria B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Brian Villarreal",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Melissa B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Philip Howard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Pamela C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Tony Giles",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Angela Snyder",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Barry R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Debra Huffman",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Isaiah Schultz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eric A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Virginia Hamilton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ann R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Jaime Cooper",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Paul Price",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristi C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Shirley Anderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tyler R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Shannon Wright",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Anthony Bowen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Walter P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Jacob Duncan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Marie B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Anthony David",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ryan B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Bradley Obrien",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sherry B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Shane Wilkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandon W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Luke Eaton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Holly C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Shelley Torres",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christine B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Stephen Franklin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Johnny H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Michael Moore",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Cindy Weaver",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Chris S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Jamie Vega",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tara R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Robert Wood",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Phillip M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Tiffany Torres",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Kelly Holmes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Katherine Newton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Robert Hill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Margaret L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Jill Carter",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Derek M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Amanda Vasquez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Angela J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Eric Reynolds",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Joel Cooper",
        "service_type": "Custom Garage Door Design",
        "tech_name": "April W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Melissa Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "David Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brent W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Veronica Stewart",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Roger Richards",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Eric Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Mary Graham",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brittany K.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Janet Calderon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mary M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Cheryl Bush",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Paula J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Zachary Coffey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cynthia H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Barbara Ho",
        "service_type": "Garage Door Services",
        "tech_name": "Patrick G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "William Valenzuela",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Kevin Carr",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Aaron Pope",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "George N.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Carol Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Austin R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Dakota White",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Austin Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Matthew S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Charles Sanchez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jose W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Erin Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jason F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Austin Moreno",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tracie B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "John Taylor",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Peter J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Anthony Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Andrea Adams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Paul B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Edgar Andrews",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anna G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Robin Gonzalez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gary H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Susan Doyle",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Denise S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Christopher Parker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Carol West",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Christine Walker",
        "service_type": "Garage Door Repair",
        "tech_name": "Christine R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Carl Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katie C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Tracy Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Shawn Swanson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Rhonda Nguyen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Megan L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Alexandra Thomas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jack T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Kristin Craig",
        "service_type": "Garage Door Insulation",
        "tech_name": "Janet M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Brian Hunt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amanda P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Alexandra Baker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan K.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Tina Bailey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jo H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Hancock",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Victor N.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Lisa Miller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Erica G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Carol Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Susan Stevens",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Wesley K.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Meagan Salazar",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie K.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Emily Webb",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Howard W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Andrew Wright",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Karen Franklin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mary F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Beltran",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Caitlin H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Mrs. Lori Solomon",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ryan D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Kari Wood",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Grace M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Beth Kim",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Ryan Ortega",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Chris Moore",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Glenda T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Cheryl Gross",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Logan H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Lisa Shaw",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Logan H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Robert Fuentes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Curtis P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Justin Davidson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cody G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Nicole Lewis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Crystal R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Jonathan Lewis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gary T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Daniel Robinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Carmen L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Laura Kim MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Samuel W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "James Reed",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Amy Perez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandy H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Brandon Lyons",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laura C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Mrs. Deborah Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Dustin Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Kristy Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Maurice L.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Molly Schmidt",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Emily K.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Albert Richardson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Danielle Powell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Shawn L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Jay Pena",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Martin White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Lauren Bradley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carla C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Cheryl Bray",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Hannah D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Barbara Davila",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christine S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Alicia Lopez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jason D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Leah James",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ricky E.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Jared Bowers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Norman G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Justin Ross",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kelly B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Benjamin Warner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sheila D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Erik Morris",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristie D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Anthony Hart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bobby C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Erika Page",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Philip H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Michael Carter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kayla S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Olivia Adams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jamie P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Sandra Green",
        "service_type": "Garage Door Installation",
        "tech_name": "Spencer P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Jessica Craig",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "John Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rebecca E.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Michael Bush",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacqueline M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Darren Moore",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Jonathan Castro",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark O.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Mark Jackson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Allison S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Deanna Reeves",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Allison Collins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heather R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Patricia Irwin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Michael Marshall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bruce A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Jessica Escobar",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "John Nunez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Benjamin R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "William Henry",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cynthia S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Kimberly Carter",
        "service_type": "Garage Door Installation",
        "tech_name": "Shaun N.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Trevor Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gina D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Mrs. Christine Reyes MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alyssa O.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Kevin Bowen",
        "service_type": "Garage Door Opener",
        "tech_name": "Sabrina K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Kathy Washington",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kaitlin S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Jason Frazier",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Chad Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dustin B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Nicholas Parrish",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rodney T.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Mrs. Alicia Fitzgerald",
        "service_type": "Garage Door Repair",
        "tech_name": "Janice P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Gregory Ward",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ana W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Donald Espinoza",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jackie C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Miguel Vance",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Barbara Murray MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tyler C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Matthew Scott",
        "service_type": "Garage Door Opener",
        "tech_name": "Phillip B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Nicole Newton DVM",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Amanda Burnett PhD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Katie Fox",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Valerie J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Cassandra Reynolds",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Jessica Butler",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Nicole Hansen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Anthony Johnston",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sandra M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Jasmine Meyer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Walter W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "James Stuart",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Edwin W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Jill Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nathan P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Michael Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Michelle Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Kristina A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Gloria Reyes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Scott M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Deborah Castillo",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexander B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Kelsey Lambert",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Kaylee Scott",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mercedes H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Patricia Dunlap",
        "service_type": "Garage Door Installation",
        "tech_name": "Christina W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Joseph James",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James L.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Jesse Wright",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Brandy Collins DVM",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Jeffery Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Benjamin G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Frederick Rice",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Karen S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Ashley Pace",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Lisa Martin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amanda A.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Paul Chan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lindsey C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Amber Serrano",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Gary Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jason M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Erin Montoya",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Jesse Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Megan S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Amanda Powell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shelley O.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Richard White",
        "service_type": "Garage Door Services",
        "tech_name": "Jesse C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Valdez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Laura F.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Roberto Kemp",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shawn R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Frank Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jody D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Jesse Edwards",
        "service_type": "Garage Door Opener",
        "tech_name": "Erica N.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Jeremy James",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Devon Parker",
        "service_type": "Garage Door Opener",
        "tech_name": "Brooke J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Vanessa Ward",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Shelby Fisher",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Samuel M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Wyatt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Kimberly Rojas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Juan C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Victoria Andrews",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Daniel G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "John Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Holly Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Juarez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melissa B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Laura Arroyo",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Helen R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Robert Carey",
        "service_type": "Garage Door Opener",
        "tech_name": "Anthony S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Mrs. Pamela Ramos DDS",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Vanessa Lawson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Justin D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Gregory Morgan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Karen A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Michael Douglas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daisy O.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Cheryl Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Latoya M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Derek Gallegos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sharon P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Erika Terry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Heidi Cowan",
        "service_type": "Garage Door Opener",
        "tech_name": "Ian G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "John Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Isabel R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Benjamin Kelley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Marc Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Scott Harris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kathleen J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Shannon Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Dana Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rebecca J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "John Atkins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mary C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Wesley Jacobs",
        "service_type": "Garage Door Opener",
        "tech_name": "Bradley G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Michele Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Hannah R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Jeremy Soto",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Colton G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Lauren Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Monica N.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Melinda Gordon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lee W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Baldwin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kevin H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Ashley Hogan",
        "service_type": "Garage Door Opener",
        "tech_name": "Jill M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Pamela Long",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Allen C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Matthew Holmes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Garrett T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "James Lawrence",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Victoria T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Mitchell Evans",
        "service_type": "Garage Door Installation",
        "tech_name": "Trevor M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Jared Guerrero",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephen F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Michael Solis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kenneth N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Destiny Skinner",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Susan O.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Mary Stewart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Paul Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samantha H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Ruth Shaffer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Teresa J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Robert Hunt",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeremiah S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Michael Gray",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dana N.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Gary Santos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angela S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Brenda Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Harold Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Johnny C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Kim Greene",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Timothy Mejia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sabrina W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Vanessa Wilson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Christina Stewart",
        "service_type": "Garage Door Services",
        "tech_name": "Charles J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Russell Patterson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bobby S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Amber Parker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brenda N.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Jo Bailey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andrea R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Crystal Reyes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Meagan J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Omar Brady",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Michelle Jordan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Courtney Pruitt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emma M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Jennifer Knight",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Veronica P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Brian Wheeler",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melissa H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Amanda Walker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joshua F.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Dr. Shelley Mcgrath",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Molly M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Mary Munoz",
        "service_type": "Garage Door Installation",
        "tech_name": "Nancy B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "James Banks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kim J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Cody Gutierrez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Stacy Hines",
        "service_type": "Garage Door Services",
        "tech_name": "Terrence R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Harold Villegas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kayla E.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Wanda White",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patrick R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Helen Schultz",
        "service_type": "Garage Door Installation",
        "tech_name": "Adam V.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Edward Bates",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heidi W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Abigail Cole",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Delgado",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Theresa C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Erin Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Rhonda Carter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Miguel F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Christopher Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Melissa R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Stephanie Torres",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Karen B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Andrea Harper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Morgan Sutton",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Robert Jennings",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Donna A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Elliott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christy M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Mr. John Greene",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Benjamin B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Lisa Brooks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Richard P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Michael Gutierrez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Barbara G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Lucas Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Paul L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Patricia Sanchez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jamie C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Dawn Webster",
        "service_type": "Garage Door Installation",
        "tech_name": "Melissa W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Katie Ferguson",
        "service_type": "Garage Door Opener",
        "tech_name": "Susan H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Ashley Wong",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "William Lewis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Elizabeth M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Steven Orozco",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tasha S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Courtney Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Andrew Freeman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dalton P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Sandra Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Dylan Gonzalez",
        "service_type": "Garage Door Opener",
        "tech_name": "Anthony B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Brenda Holder",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sarah R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Kenneth Moore",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ashley W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Dr. Taylor Jordan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christina O.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Steven Gonzalez",
        "service_type": "Garage Door Services",
        "tech_name": "Scott W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Jessica Jackson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shelby H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Michael Conley",
        "service_type": "Garage Door Services",
        "tech_name": "Nancy T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Anthony Preston",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Stephanie Chase",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heather B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Antonio Garcia",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Corey T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Justin Moore",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melanie B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Green",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christian B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Michael Galloway",
        "service_type": "Garage Door Repair",
        "tech_name": "Megan L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Ashley Daniel",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Melanie Cameron",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rachel B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Danielle Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Troy W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Zachary Kelley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Bridget Rodriguez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Sarah Dougherty",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Douglas C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Douglas Grant",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tiffany J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Ashley Cook",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Sandy Day",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kayla G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Jason Parker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tracy N.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Aaron Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Janet W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Amanda Carson DVM",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Teresa H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Thomas Gomez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Glen Ellison",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jamie Alvarado",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Joseph Mcdaniel",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nathan L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Stephanie West",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sarah M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Jessica Rodriguez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeremy H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Melissa Chen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William A.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Kevin Donovan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Crystal E.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Ralph Cruz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristine W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Amy Colon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tammy L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Dennis Ross",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jorge V.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "John Robles",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tiffany F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Michelle Allen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Lisa Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Barbara C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Angela Perry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anthony F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Travis Walker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Allison Mullins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shannon C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Savannah Sutton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Krystal F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "David Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Emily R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Walter Roberts",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Erin P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "David Jordan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paul A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Adrienne Bradley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rachel B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Brent Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Dr. Christina Baker MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Dunn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nancy B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Luis Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Nancy M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Bridget Murphy",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Paul Obrien",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Natalie H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "John Castro",
        "service_type": "Garage Door Opener",
        "tech_name": "Mary S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Robert Mills",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tracy S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Heidi Diaz",
        "service_type": "Garage Door Services",
        "tech_name": "Allison R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Jessica Anderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Edward H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Henry Cooper",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Tim Perez",
        "service_type": "Garage Door Repair",
        "tech_name": "Todd J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Tiffany Tate",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Antonio P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Sherry Sanders",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Tracey Russell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Rodney Cantu",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cynthia W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Victoria Forbes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "David Gonzalez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "William Stewart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Yolanda L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Randy Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Lindsay Taylor",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tammy L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Ashley Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Samuel Moon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tammy A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Jacob Diaz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Craig H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Martin Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jill J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Julia Hicks",
        "service_type": "Garage Door Installation",
        "tech_name": "Anthony C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Marissa Ross",
        "service_type": "Garage Door Services",
        "tech_name": "Jeremy S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Albert Luna III",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Victoria P.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Michael Sims",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kevin C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Joseph Flores",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Trevor Bennett",
        "service_type": "Garage Door Repair",
        "tech_name": "Bonnie B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Steven Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adriana V.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Brian Brown MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jared Y.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Jesus Casey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Caleb C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Samantha Woods",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Loretta H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Scott Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jacob A.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Erica Parker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Karen Martin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Justin Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Hannah M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Robert Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cheryl M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Cynthia Mullins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heather E.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Alison Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Catherine Riddle",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cameron W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Mary Schultz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrea W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Javier Walters",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Paige Larson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Justin B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Leslie Green",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Allison Skinner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anna B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Alexandra Wade",
        "service_type": "Garage Door Installation",
        "tech_name": "Sara M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Joyce Mcdonald",
        "service_type": "Garage Door Services",
        "tech_name": "David J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Robert Wilson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lindsay P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Lindsay Bruce",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James O.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Max Cook",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Natalie C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Adrian Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Toni C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Brenda Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cynthia G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Brittany Boyer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Christopher Gray",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lauren L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jasmine Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Thomas M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Carrie Martinez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jill W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Joshua Meyers",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Chloe S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Dr. Daniel Aguirre II",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Allison W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Isabella Henderson",
        "service_type": "Garage Door Services",
        "tech_name": "Danielle F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Christopher Brewer",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Wright",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Jesus Mills",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Ashley Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Cynthia B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Jesse Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dana N.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Jon Barnett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kim W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Jillian Reed",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anna V.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Jonathan Chan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Megan Nelson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amber M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Steven Black",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shannon H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Paige Perez",
        "service_type": "Garage Door Opener",
        "tech_name": "Felicia J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Bobby Hunt",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Caitlin M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Amy Payne",
        "service_type": "Garage Door Repair",
        "tech_name": "Gerald P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Chad Mcdaniel",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tricia R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Jennifer Lester",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Ricky Boone",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kenneth G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Gary Osborn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Pamela S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Jeffery Bruce",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandon N.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Jerry Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher Y.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Ashley Rubio",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Raymond S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Brandon Ferguson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephen H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Janet Soto",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melanie W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Ricky Gonzalez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Douglas T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Katrina Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Megan Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tommy P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Michelle Cervantes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anne R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Mary Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ryan E.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Mr. Michael Myers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Debbie F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Christian Hess",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa N.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "David Nunez",
        "service_type": "Garage Door Services",
        "tech_name": "Brittney H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Steven Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dennis C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Chris Obrien",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Dawn Soto",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shane M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Colon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Weber",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Susan Y.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Tyrone French",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anna B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Diane Underwood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Crystal G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Roberto Dean",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tanya M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Sarah Taylor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Debra M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Matthew Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Heather C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Michael Howell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer P.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Mark Ryan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dawn W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "John Le",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrea S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Sydney Hopkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Jonathan Vasquez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "John Townsend PhD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Kimberly Lowe",
        "service_type": "Garage Door Services",
        "tech_name": "Jacob J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Brian Juarez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Emma J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Wilson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shawn S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Sonya Torres",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Morgan B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Patricia Nelson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joanna C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Cindy Lopez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Katherine G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Belinda Colon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Bethany Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Douglas Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Luke Burns",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kyle F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Thomas Bryant",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jodi C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Joseph Marshall",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Tracy Collins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Hannah Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amber H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Christine Morrison",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Mrs. Stephanie Flowers MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mitchell J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Nicholas Jimenez",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Pam Murray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Maria H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Joseph Hull",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joshua R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Daniel Anderson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sandra L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Bianca Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tyler H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Michael Maldonado",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Richard Oliver",
        "service_type": "Garage Door Repair",
        "tech_name": "Sharon J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Candace Ramirez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Randy N.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Alexander Hayes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cynthia H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Kimberly Paul",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paul T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Tina Gallegos",
        "service_type": "Garage Door Repair",
        "tech_name": "Craig J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Sharon Hatfield",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Michael Waller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason V.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Derek Stone",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sean B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Cameron Mitchell Jr.",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa K.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Caitlyn Austin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sean B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Sarah Mitchell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Ross",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Barbara Khan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Wendy S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Curtis Lawrence",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amanda J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Danny Barker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gabrielle M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Toni Robinson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Charles Norris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sharon C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Ashley Nguyen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melissa W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Steven Graves",
        "service_type": "Garage Door Off Track",
        "tech_name": "Anthony B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Sherri Suarez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeremy J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Jordan Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Charles L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Heather Mccann",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gary B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Jesse Bautista",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michelle T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Christine Collier",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Sharon Gonzalez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephen H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Bruce Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dalton C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Christopher Harris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tyrone A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Carla Turner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jamie S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Amanda Deleon",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffery T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Sean Ward",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Shelly Turner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Darius B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Michelle White",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kayla D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Michelle Horton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cynthia C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "John Hodges",
        "service_type": "Garage Door Opener",
        "tech_name": "Jack S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Raymond Vazquez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan N.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Diana Bennett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Hector B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Tara Campbell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Katherine W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Felicia Suarez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jesse S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Elijah Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kevin P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Robert Harris MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jonathan E.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Madison Perkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sandra B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Stephen Fleming",
        "service_type": "Garage Door Repair",
        "tech_name": "Olivia R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Sylvia Joseph",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robin H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Sandra Perez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Bishop",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Eugene W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Joel Parker",
        "service_type": "Garage Door Installation",
        "tech_name": "Jonathan S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Gina Stewart",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kaitlin O.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Matthew Delgado",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carolyn B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Charles Singh",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Stephanie Ramos",
        "service_type": "Garage Door Repair",
        "tech_name": "Douglas P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Richard Willis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Jonathan Hines",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amy W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Michael Bird",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jose B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Mary Yang",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Holly J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Heather Buchanan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Terri Z.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Sharon Turner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gabrielle A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Paul Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lori J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Michael Griffin MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lauren R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Cassandra Mendez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Mark Knight",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jose M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Gina Valenzuela",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Robert White",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicholas L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Nicole Ware",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Briana M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Thomas Thompson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Sharon Lucas DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gregory M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "William Foster",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brandon R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Brandon Ray",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Mark Baird",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Karen P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Stephen Gutierrez",
        "service_type": "Garage Door Repair",
        "tech_name": "Calvin S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Lindsay Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charlene C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Christina Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Joseph Gordon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Sara Singh",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patricia W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Monica Allen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Allen I.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Mike Petty",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patricia P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Alexander Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Theresa S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Tyrone Dennis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sara J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Vanessa Miller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth N.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Amy Powell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amanda W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Thomas Hamilton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrea B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Alicia Jordan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Patricia G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Amber Melendez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jerry J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "William Fisher",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Karen Castro",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Steven W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Valerie Parsons",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alexandra T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Justin Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Jonathon W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Dennis Summers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Krista Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Benjamin F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Carrie Schultz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David L.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Bonilla",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Daniel W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Misty Perez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Denise Moreno",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mark R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Maldonado",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Karen Griffin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ronald A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Ronald Anthony",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Diane M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Patricia Gardner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Pamela M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "William Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Eddie D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Sandra Wagner",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paul K.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Robert Lang",
        "service_type": "Garage Door Installation",
        "tech_name": "Stanley Y.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Darrell Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Stephanie Lane",
        "service_type": "Garage Door Services",
        "tech_name": "Jonathan R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Rachael Mann",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nancy D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Anna Lopez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Daniel Morgan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mitchell R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Gabriel Brooks",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bonnie W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Eric Thomas",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Rita Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Theresa C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Laura Best",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kevin H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Brittany Parker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Travis H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Stephen Jensen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christine K.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Zachary French",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Adrian Brennan",
        "service_type": "Garage Door Opener",
        "tech_name": "Rachel G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Laura Hall",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Patrick Owens",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Kevin Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Haley H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "David Miller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Debra F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Brianna Newman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffrey S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Mr. Ricky Shepard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bruce B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Matthew Perry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Richard Foster",
        "service_type": "Garage Door Services",
        "tech_name": "Nancy G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Sarah Perez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Julia Brooks",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gabriella T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Erika Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristine S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Jeremy Phillips",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alexander H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Morris",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Justin M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Dr. Antonio Perry",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Dale Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Vicki C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Lisa Harris DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gina H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "David Jenkins",
        "service_type": "Garage Door Opener",
        "tech_name": "Scott G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Karen Morris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Katelyn H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Laura Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tiffany B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Kelly Wood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Angel Edwards",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Heather Hicks",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gregory H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Ronald Castillo",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Diane T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Bradley Ellis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Patrick F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Daniel Russell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrea M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "David Bernard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Jason Alvarez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brianna M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Michael Schmitt",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael E.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Cynthia Cunningham",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle N.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Timothy Atkinson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Margaret O.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Tonya Hart",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michelle O.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Carlos Herrera",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alicia T.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Ian Haynes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Todd D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "William Stanley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paul B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Alexander Zuniga",
        "service_type": "Garage Door Services",
        "tech_name": "Dustin J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Kathy Juarez",
        "service_type": "Garage Door Services",
        "tech_name": "David T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Michael Patel",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Megan Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristin W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "David Sullivan",
        "service_type": "Garage Door Installation",
        "tech_name": "Cody J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Michael Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Danielle Armstrong",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shelley W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Kristine Garrison PhD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alexandria H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Richard Sanders",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tiffany G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Alan Newman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Donald Taylor",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Samantha Rose",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Krista R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Christine Torres",
        "service_type": "Garage Door Insulation",
        "tech_name": "Todd H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Nicholas Hensley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sally H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Michelle Best",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Katelyn L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Terrence Gardner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Vanessa Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cynthia J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Jeffery Phillips",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Katherine Farmer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Seth L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Kyle Pineda",
        "service_type": "Garage Door Installation",
        "tech_name": "Haley V.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Gina Knight",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeffrey C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Beverly Martinez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Garrett W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Renee Cox MD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Victor B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Jodi Boone",
        "service_type": "Garage Door Repair",
        "tech_name": "Gilbert Y.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Timothy Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Karen N.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Maureen Campbell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "John Lewis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Philip G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Susan Hanna",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Catherine G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Jessica Webb",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeff H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Heather Davidson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Lindsey Franklin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Jessica Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephen L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "John Meyer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kristen B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Bruce Monroe",
        "service_type": "Garage Door Repair",
        "tech_name": "Lindsay M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Kelly Frost",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kenneth A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Anna Newman",
        "service_type": "Garage Door Installation",
        "tech_name": "Christina B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Timothy Goodwin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Elizabeth S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Robert Cordova",
        "service_type": "Garage Door Opener",
        "tech_name": "William S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Jason Romero",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christian R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Ashley Avila",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Andrea Young",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Thomas Hill",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shelly D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Linda Hill",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Kendra Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Nicholas M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Scott Peck",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Jon Gould",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sheryl G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Brenda Gray",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Logan P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Gregory Evans",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Russell B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Jennifer Haley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Belinda C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Tanya Washington",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Justin Morgan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Sharon Murphy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Paul B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Paul Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "John Wallace",
        "service_type": "Garage Door Repair",
        "tech_name": "Brenda D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Jasmine Merritt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Betty M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "John Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Susan N.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Johnathan Castro",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Jennifer Harper",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Charles Reed",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Cory Fuller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dylan W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Tyler Jensen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stacy O.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Julie Myers",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Caitlyn Gomez",
        "service_type": "Garage Door Services",
        "tech_name": "Amanda W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Tamara Aguirre",
        "service_type": "Garage Door Repair",
        "tech_name": "Carrie L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Gomez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Bruce Ruiz",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maria A.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Michael Lewis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Nicholas Collins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Emma G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Albert Andrews",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tim S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Leslie Contreras",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Keith H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Rachel Chaney",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Veronica D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Rachel Christensen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Heather B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Patricia Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gina T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Tara Jordan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jonathan M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Stephen Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Johnny D.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Paul Sullivan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Gary Oliver",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brooke T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Jonathan Best",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Jose Howard",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Barbara Mcdaniel",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jermaine L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Anthony Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Denise Oliver",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karen A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Katie Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joe L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Regina Ruiz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Walter B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Shannon Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Allen Kelley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Linda F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Sara Collins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rebecca D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Mary Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Veronica M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Kayla Perry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Aaron R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Jerry Clark DDS",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brooke W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Andrew Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mackenzie G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Tracy Ward",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Edward K.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Robin Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Kyle Payne MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Annette M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Colin Cruz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Annette A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Melissa Mitchell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Johnny E.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Anthony Esparza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Latasha Reynolds",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Kristi Ryan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dennis M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Ann Nguyen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Emily R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Aaron Evans",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Karen B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Glenn Kerr",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gavin B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Shane Page",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Lonnie Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samantha S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Michael Wright",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bethany H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Jeremy Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amanda B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Tyler Stevens",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joan O.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Patrick Hartman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brendan D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Edward Allen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Laura Anderson MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Charles T.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Peggy Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Leslie S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Ronnie Terrell",
        "service_type": "Garage Door Opener",
        "tech_name": "Patrick K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Karen Harris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kelsey R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Timothy Parker",
        "service_type": "Garage Door Installation",
        "tech_name": "Janet B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Amy Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Noah J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Susan Neal",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Donna T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Kathy Oneal",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kevin R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Kellie Peterson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jacqueline R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Jerry Miller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Aaron R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Beverly Hughes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Frances W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Alexandria Glover",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bill H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Tyler Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew V.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Devin Banks",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua V.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Edwin Flores",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jermaine S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Anne Baker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mike R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Carl Parker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Keith Joseph",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kenneth S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Chelsea Willis",
        "service_type": "Garage Door Opener",
        "tech_name": "Olivia L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Taylor Carr",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Valerie M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Ronald Washington",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew T.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Christopher White",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joan O.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Amanda Hansen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Pamela S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Michael Ochoa",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laura R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Jonathan Kelley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ronald B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Tiffany Byrd",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Don J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Jessica Hammond",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jacob W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Sara Bass",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Haley Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Daniel Le",
        "service_type": "Garage Door Insulation",
        "tech_name": "Theresa H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Cantrell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Andrew Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Misty Cummings",
        "service_type": "Garage Door Off Track",
        "tech_name": "Briana R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Jonathan Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew O.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Katelyn Black",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Philip C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Diane Williamson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Audrey Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patricia S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Kathryn Gordon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bernard P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Robert Weber",
        "service_type": "Garage Door Installation",
        "tech_name": "Teresa B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Allison Russell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dan R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Shannon Holt",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alisha B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Ronnie Wood",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patrick L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Scott Wood",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Courtney Bishop",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Jason Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Sara Brewer",
        "service_type": "Garage Door Installation",
        "tech_name": "Timothy A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Morrison",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shannon S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Sergio Garner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Oscar C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Kelly Harper",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Brent Bell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Christina Hanson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Lisa Sherman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Sara Baker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sara S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Stephanie Romero",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jacob J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Mrs. Anna Parker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Danielle B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Barbara Diaz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Alexandra Hoffman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elizabeth G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "James Walker",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan Y.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Vincent Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lauren C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Dennis Robinson",
        "service_type": "Garage Door Repair",
        "tech_name": "Francisco W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Michelle Tate",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jamie T.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Lisa Palmer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stephanie E.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Robert Holloway",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "April L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Jaime Dean",
        "service_type": "Garage Door Services",
        "tech_name": "Kathryn S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Brian Contreras",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeremy V.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Sydney Murphy",
        "service_type": "Garage Door Installation",
        "tech_name": "Ryan N.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "William Ward",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrea L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "William Huffman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melinda C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Jorge Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dawn J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Alec Arnold",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristen M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Victoria Vazquez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bruce M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Adam Meyer MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa V.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Adam Wood",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gary C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Mrs. Amy Allen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelly C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Johnathan Ross",
        "service_type": "Garage Door Opener",
        "tech_name": "William F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Pierce",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ernest S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Pamela Wood",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bruce C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "David Mendoza",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Jeremy Logan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Eric Rogers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Keith G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Nicholas Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Victor Avery",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicole T.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Ryan Duncan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lauren J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Lauren Hall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Diane C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Kevin Fritz MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Eric D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Jennifer Sullivan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Neil C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Elijah Navarro",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stacey R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Gavin Morales",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "George Ramos",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Aaron H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Matthew Lewis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Anthony C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Dana Schwartz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sarah T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Christopher James",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Maria P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "John Saunders",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jade H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Angela Rivera",
        "service_type": "Garage Door Installation",
        "tech_name": "Ian S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Anthony Coleman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paige P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Chelsea Mahoney",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danielle P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Lisa Anderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Russell P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Ryan Munoz",
        "service_type": "Garage Door Opener",
        "tech_name": "Sheryl R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Logan Spears",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Valerie Page",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kelli C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Megan Ray",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Wayne N.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Kevin Shelton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Douglas R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Keith Payne",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Mark Scott",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robin M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Lacey Adams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cheryl A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Kathy Santana",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brittany O.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Erica Chase",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jose Z.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Katherine Cuevas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Luke C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Sarah Escobar",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angela M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Stephanie Mooney",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Susan Ritter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Ashley Ortiz",
        "service_type": "Garage Door Repair",
        "tech_name": "Jill H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Travis Trujillo",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gary W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Devin Lucas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brenda A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "April Todd",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicole W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Melissa Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Emily B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "George Ferguson",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Casey Guzman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Megan H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Benjamin Hawkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Karen G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Timothy Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Tiffany Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Elizabeth M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Destiny Hernandez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Lucas Clarke",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Kathryn Rhodes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katie K.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Christina Moore",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Wendy V.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Richard Wood",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Suzanne C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Ryan Leach",
        "service_type": "Garage Door Opener",
        "tech_name": "Leslie B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Jennifer Finley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher F.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Stanley Craig",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Leslie L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "David Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Paul S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Randy Leonard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Monique H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Kim Mays",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "George A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Bianca Hahn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Daniel Bush",
        "service_type": "Garage Door Insulation",
        "tech_name": "Teresa E.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "John Holt",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Charles R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Molly Butler",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tracey H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Robert Cole",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandi H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Stephanie Christensen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Timothy P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Emma Harrison",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephen P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Joy Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Jordan Garrett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jenna M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Lori Combs",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Benjamin L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Lori Ortiz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Haley Gonzalez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandon A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Gabrielle Bryant",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Chad Atkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Benjamin J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Richard Farmer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jose W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Stephanie White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Desiree W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Carrie Bradshaw",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Rebecca Ellison",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Taylor V.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Shari Bray",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mason G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Russell King",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Jill Taylor",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alexis P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Jonathan Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amy G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Jason Mejia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carolyn V.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Joseph Diaz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Ann Boyd",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Erin Thomas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gregory A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "James Gonzalez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lori L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Ronald Kim",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katherine W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Austin Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Scott H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Brenda Mcbride",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Richard B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Dale Robinson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jacob C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Kelly Huang",
        "service_type": "Garage Door Opener",
        "tech_name": "Jonathan T.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Crystal Pineda",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lance L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Diana Price",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Henry Maynard",
        "service_type": "Garage Door Installation",
        "tech_name": "Garrett M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Suzanne Escobar",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dawn S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Brandon Cook",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly N.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Benjamin Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Seth Morton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Shane Bowers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Patricia M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Corey Massey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alec R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Jimmy Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Barry S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Rose",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Susan M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Brooke Parker",
        "service_type": "Garage Door Installation",
        "tech_name": "William P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Terry Woods",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Craig H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Michaela Hayden",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tiffany E.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Mrs. Sarah Mejia DDS",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "David Hill",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Natalie B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Richard Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Travis L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Maria Stafford",
        "service_type": "Garage Door Repair",
        "tech_name": "Tammy R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Wendy Walsh",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Krystal P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Jacob Thomas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Scott M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Craig Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dale E.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Dr. Amanda Bentley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erin D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "James Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Brenda Mcneil",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dillon W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "April Norton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Robert Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Heather Bridges",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amy N.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Mr. David Morgan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Maria G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Justin Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kenneth H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Robert Jackson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Edwin A.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Eric Patterson",
        "service_type": "Garage Door Services",
        "tech_name": "Tanya J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Pamela Cardenas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Leslie Zavala",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Monica B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Luis Lynch",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lynn H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Teresa Goodwin",
        "service_type": "Garage Door Installation",
        "tech_name": "Sharon B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Erica Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Savannah R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Rita Kirk",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Michael Moore",
        "service_type": "Garage Door Services",
        "tech_name": "Hannah D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Donald Acevedo",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie K.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Eddie Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carolyn J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Fred Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Patricia W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Karen Quinn",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Dustin Jordan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Michael Sullivan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Isaac R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Andrew Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Darren Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Andrew Fowler",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alexandra C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Daniel Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Roberto Sanchez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Jose Morton",
        "service_type": "Garage Door Services",
        "tech_name": "Anne W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Jessica Baker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amy Y.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Jeremy Morales",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tyler T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Marcus Becker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Felicia H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Charles Short MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephen W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Jason Yates",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Vincent Gallagher",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kathryn L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Ian White",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Michelle White",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Brandon Barnes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Adam Pruitt",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joann H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Renee Arellano",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin K.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Jennifer Wilson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stephanie A.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Mariah Burton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bryan H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Eric Rhodes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cesar P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Matthew Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dylan R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Nicholas Oliver",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Ronald Valentine",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Spencer Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Michael Collier",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Nicholas Adams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Chad V.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Kyle Gordon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tiffany J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Kristina White",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Wayne Valentine",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Thomas Perez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Corey C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Anthony Whitehead",
        "service_type": "Garage Door Repair",
        "tech_name": "Madison T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Patrick",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Austin L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Eric Castillo",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Beth R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Justin Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Bill Snyder",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Andrew Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Karen O.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Ryan Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Katelyn Solis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Erica S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Samantha Rowland",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Courtney P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Jonathan Powers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Crystal S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Michelle Griffin",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Andrew Matthews",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jason F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Willie Mack",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jon W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Jennifer Taylor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrea P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Ronnie Melton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Katherine P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Jason Carr",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Monica A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Matthew Garner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dakota B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Thomas Le",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dana M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Christy Maxwell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Randy W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Frank Lewis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kayla M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Leah Briggs",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rodney S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Kimberly Rich",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Victoria H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "David Pena",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Jessica Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Pamela Duran",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Carl Gordon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katelyn S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Christina Escobar",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andre G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Tonya Carter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Deborah S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Joe Martinez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Donald M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Bonnie Scott",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dana J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Jennifer Turner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brent J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Joseph Adams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly A.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Michael Green",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amber J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "William Sampson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason O.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Diaz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tom G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Debra Hayes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patricia J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Kimberly Sanders",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ariel J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Stevens",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alan J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Gary Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daniel B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Tammy Duran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michelle W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Sara Bell",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Paula Bean",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Edward M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Francisco Burton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christine T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Jessica Schwartz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Brianna Simmons",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Wright",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Judith J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Joseph Barker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Sara Brady DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Clifford Q.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Jennifer Fry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sarah R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Rebecca Ware",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hester",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Frank H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Michael Bates",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Julie M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Phyllis Jacobson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dustin W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Robert Alexander",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shannon B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Sarah Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joshua K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Craig White",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Briana Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Latoya T.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "David Rodriguez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Isaac B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Nicholas Dougherty",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Olivia Gomez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christine A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Robin Campbell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ryan R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Michelle Stone DDS",
        "service_type": "Garage Door Services",
        "tech_name": "Rebecca W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Wanda Aguilar",
        "service_type": "Garage Door Services",
        "tech_name": "John M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Christopher Stewart",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Virginia R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Kyle Alvarado",
        "service_type": "Garage Door Services",
        "tech_name": "Brian M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Sandra Diaz DDS",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Anita Leonard",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robin S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Amy Perez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Diana N.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "William Diaz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Susan P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Andre Long",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sarah K.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Christy Riddle",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Amber Rivera",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Anthony Christensen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Charles Mendez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Catherine Horne",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Thomas Bernard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angela D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Nicholas Mayer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tina D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Willie Good",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher U.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Daniel Brown",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jasmine B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Robert Robinson",
        "service_type": "Garage Door Repair",
        "tech_name": "Karl G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Cynthia Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Frank Zimmerman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alison W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Susan Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Larry W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "David Warren",
        "service_type": "Garage Door Off Track",
        "tech_name": "Patricia S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Jose Lutz",
        "service_type": "Garage Door Installation",
        "tech_name": "Melissa C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Patrick Brooks",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Joshua Armstrong",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sheri O.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Monica Lester",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Juan Nguyen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Amy Terry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Mr. Joshua Lang Jr.",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Dana Woods",
        "service_type": "Garage Door Opener",
        "tech_name": "Sandra B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Alexandria Lucas",
        "service_type": "Garage Door Services",
        "tech_name": "Brandy R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Belinda Ward",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Phyllis W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Charles Guerrero",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Leslie W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Robert Miller",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Timothy Cooper",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cory D.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lori A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Debra Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carla L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Brady Robinson PhD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anthony G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Michael Reynolds",
        "service_type": "Garage Door Installation",
        "tech_name": "Oscar S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Jennifer Thompson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jillian A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Dawn Walker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kathy G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Andrew Obrien",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Kimberly Mcintyre",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lawrence M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Faith Mckenzie",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Melanie Cruz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Isaiah M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Karen Mendez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Lauren Taylor",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Stephanie Robinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebecca J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Matthew Lester",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Richard F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Nicholas Moore",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lucas C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Beverly Morton",
        "service_type": "Garage Door Repair",
        "tech_name": "Calvin M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "James Clark",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacqueline G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Robert Cook",
        "service_type": "Garage Door Opener",
        "tech_name": "Jason H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "John Ferrell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dustin J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Whitney Boyd",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Adam W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Adriana Davenport",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Eric Berry",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donald F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Mr. James Page",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Barbara B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Kevin Clark",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Aaron S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Madison Douglas",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Katie B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Kristen Bennett",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Justin R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Scott Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Susan Harper",
        "service_type": "Garage Door Repair",
        "tech_name": "Richard F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Chris Romero DVM",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Denise Villarreal",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelsey H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Raven Ross",
        "service_type": "Garage Door Installation",
        "tech_name": "Sean M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Ricky Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Anthony Sutton",
        "service_type": "Garage Door Services",
        "tech_name": "Jacqueline T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Lisa Cook",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jason D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Dustin Terry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ernest M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Luis Ho",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Jo Hoffman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jon M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Richard Sellers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Jared Black",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amy C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Daniel Dalton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Adam M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Steve Daniel",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kathleen A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Troy Sexton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ann P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Jordan Estrada",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Renee C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Kelsey Hoffman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cody T.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Caleb Armstrong",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristen M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Abigail Jenkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Helen W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Tiffany Powers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Anthony Castro",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin F.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Susan Parsons",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Suzanne K.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Joseph Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Suzanne W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Jaime Adams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gina R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Eric Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Beth Ellis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Mr. John Watson",
        "service_type": "Garage Door Installation",
        "tech_name": "Cody J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Matthew Patterson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Elizabeth L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Carl Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amanda P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Michael Silva",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Katelyn S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Andre Rodriguez MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Susan J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Garrett Jackson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brandi T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Dawn Dunn",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Amy Brooks",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Melissa Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Patricia T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "William Hooper",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melanie W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Rhonda Burke",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carl M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Melissa Kelley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Allison Moore",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Edward Mitchell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tyler A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Kim Alexander",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Robert Carson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maurice N.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Dorothy Boyer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Daniel P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Hannah Jensen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Luke Edwards",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrea R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "John White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jesus W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Mary Walker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christy G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Lauren Deleon",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Heather Pope",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patrick P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Christopher Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
